import { createReducer, on } from '@ngrx/store';
import { productActions } from '../actions/product.actions';
import { Product } from '@app/store/models';

export interface ProductState {
    loaded: boolean | null;
    productDetails: Product | null;
    productDescription: string | null;
    cardExists: boolean;
}

export const initialState: ProductState = {
    loaded: null,
    productDetails: null,
    productDescription: null,
    cardExists: false,
};

export const productReducer = createReducer(
    initialState,

    on(
        productActions.getProductDetails,
        (state): ProductState => ({
            ...state,
            productDetails: null,
            productDescription: null,
            loaded: null,
        }),
    ),
    on(
        productActions.getProductDetailsSuccess,
        (state, payload): ProductState => ({
            ...state,
            productDetails: payload.product,
            loaded: true,
        }),
    ),
    on(
        productActions.getProductDetailsError,
        (state): ProductState => ({
            ...state,
            productDetails: null,
            loaded: false,
        }),
    ),
    on(
        productActions.getProductDescriptionSuccess,
        (state, { value }): ProductState => ({
            ...state,
            productDescription: value.description,
            cardExists: value.cardExists,
        }),
    ),
    on(
        productActions.getProductDescriptionError,
        (state): ProductState => ({
            ...state,
            productDescription: null,
        }),
    ),
    on(
        productActions.clear,
        (): ProductState => ({
            ...initialState,
        }),
    ),
);
