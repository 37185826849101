import { ProductDescriptionDto } from './product-description.dto';

export class ProductDescription {
    description: string;
    cardExists: boolean;

    constructor(dto: ProductDescriptionDto) {
        this.description = dto.description;
        this.cardExists = dto.card_exists ?? false;
    }
}
