import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Lang } from '@app/i18n/lang.type';
import { ProductFilter } from '../models';

export const productFiltersActions = createActionGroup({
    source: 'Product Filters',
    events: {
        'Get Filters': props<{ categoryCode: string; lang: Lang }>(),
        'Get Filters Success': props<{ filters: ProductFilter[] }>(),
        'Get Filters Error': emptyProps(),

        Clear: emptyProps(),
    },
});
