import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs';
import { Lang } from '@app/i18n/lang.type';
import { Product } from '@app/store/models';
import { selectCardExists, selectLoaded, selectProductDescription, selectProductDetails } from '../selectors/product.selector';
import { productActions } from '../actions/product.actions';

@Injectable({
    providedIn: 'root',
})
export class ProductFacade {
    private store = inject(Store);

    loaded$ = this.store.select(selectLoaded);
    cardExists$ = this.store.select(selectCardExists);
    productDetails$ = this.store.select(selectProductDetails).pipe(filter((data): data is Product => data !== null));
    productDescription$ = this.store.select(selectProductDescription).pipe(filter((data): data is string => data !== null));

    get productDetails(): Product | null {
        let product = null;
        this.store
            .select(selectProductDetails)
            .pipe(
                take(1),
                tap((item) => (product = item)),
            )
            .subscribe();
        return product;
    }

    getProductDetails(id: string, lang: Lang): void {
        this.store.dispatch(productActions.getProductDetails({ id, lang }));
    }

    getProductDescription(productId: string, lang: Lang): void {
        this.store.dispatch(productActions.getProductDescription({ productId, lang }));
    }

    openProductCard(productNo: string, lang: Lang): void {
        this.store.dispatch(productActions.openProductCard({ productNo, lang }));
    }

    clear(): void {
        this.store.dispatch(productActions.clear());
    }
}
